import actions from './authActions'
import getters from './authGetters'
import mutations from './authMutations'
import state from './authState'

export default {
    namespaced: true,
    actions: actions,
    getters: getters,
    mutations: mutations,
    state: state
}