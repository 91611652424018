import Vue from "vue";
import VueRouter from 'vue-router';
import store from '../store';
import axios from '../axios';

Vue.use(VueRouter);
const router = new VueRouter({
  mode: 'history',
  // base: "/",
  routes: [{
    path: '/login',
    name: 'login',
    component: () => import('../pages/Login.vue')
  },
  {
    path: '/logout',
    name: 'logout',
  },

  {
    path: '',
    component: () => import('../components/Header.vue'),
    children: [{
      path: '/',
      name: 'home',
      component: () => import('../pages/Home.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/pos',
      name: 'pos',
      component: () => import('../pages/Pos.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('../pages/Register.vue'),
      meta: {
        requiresAuth: true
      }
    },

    ]
  }
  ]
});

router.beforeEach((to, from, next) => {

  axios.interceptors.response.use(response => {
    if(response && (response.status == 402 || response.status == 403)){
      store.dispatch('auth/logout');
      store.dispatch('logout');
      return;
    }
    return response;
  });

  if (to.name === 'logout') {
    store.dispatch('auth/logout');
    store.dispatch('logout');
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {

    if (!store.state.auth.isLoggedIn()) {
      next({
        name: 'logout',
        replace: true
      })
    } else {
      next()
    }
  } else {
    if (to.name == 'login' && store.state.auth.isLoggedIn())
      next({
        name: 'home'
      })
    else
      next()
  }
})

export default router;