import axios from '../../axios';
import router from '../../router';

const timestamp = () => {
  const time = new Date();
  return time.getTime();
}

export default {
    login({ commit }, payload) {
        return new Promise((resolve, reject) => {
            return axios.post("/api/pos/staff"+ "?t="+ timestamp(), { email: payload.email, password: payload.password })
                .then(response => {
                    if (response.data.status) {
                        let data = response.data.data;
                        if(data.profile.profile_type == 4 || data.profile.profile_type == 5) {
                            commit('SET_USER', data, {root: true});
                            commit('SET_BEARER', data.auth.token, {root: true});
                            commit('SET_EXPIRE', data.auth.expire);

                            resolve(response.data);
                        } else {
                            reject("Sin permisos de acceso.")
                        }
                    } else {
                        reject(response.data);
                    }
                })
                .catch(error => reject(error.response.data.msg));
        })
    },
    loginManagers(context, payload) {
        return new Promise((resolve, reject) => {
            return axios.post("/api/pos/staff"+ "?t="+ timestamp(), { email: payload.email, password: payload.password })
                .then(response => {
                    if (response.data.status)
                        resolve(response.data);
                    else
                        reject(response.data);
                })
                .catch(error => reject(error.response.data.msg));
        })

    },
    setFingerprint({ commit }, identity) {
        commit('SET_FINGERPRINT', identity);
    },
    getFingerDataToLogin({ commit }, payload) {
        return new Promise((resolve, reject) => {
            return axios.post("/api/fingers/staff/login"+ "?t="+ timestamp(),
                {
                    print: payload.print,
                    id_institution: payload.id_institution,
                    account_type: payload.account_type
                }
                ).then(response => {
                    if (response.data.status) {
                        let data = response.data.data;

                        if (data.profile.profile_type == 4 || data.profile.profile_type == 5) {
                            commit('SET_USER', data, {root: true});
                            commit('SET_BEARER', data.auth.token, {root: true});
                            commit('SET_EXPIRE', data.auth.expire);
                            return resolve(response.data);
                        } else
                            return reject(response.data);
                    }
                    else
                        return reject(response.data);
                })
                .catch(error => reject(error.response.data.msg));
        });
    },
    logout() {
        localStorage.removeItem('account');
        localStorage.removeItem('profile');
        localStorage.removeItem('expire');
        localStorage.removeItem('ingredients');
        localStorage.removeItem('finger');
        localStorage.removeItem('token');
        localStorage.removeItem('id_sale_init');
        localStorage.removeItem('start_amount');
        localStorage.removeItem('started');
        localStorage.removeItem('till_init_date');
        router.push('/login');
    },
}