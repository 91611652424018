import Vue from 'vue';
import App from './App.vue';
import rutas from './router';
import store from './store';
import vuelidate from 'vuelidate';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueHtmlToPaper from "vue-html-to-paper";
// import * as Sentry from "@sentry/vue";
// import { BrowserTracing } from "@sentry/tracing";

let options = {
	styles: ["https://cdn.jsdelivr.net/npm/fomantic-ui@2.8.4/dist/semantic.min.css"]
}

Vue.use(vuelidate);
Vue.use(VueToast, {
	position: 'top',
	duration: 3000
});
Vue.use(VueHtmlToPaper, options);

// axios
import axios from "./axios.js";
Vue.prototype.$http = axios;
Vue.prototype.$image = process.env.VUE_APP_IMAGES || '';
Vue.prototype.$sdk = new window.Fingerprint.WebApi();

// Sentry.init({
//   Vue,
//   dsn: "https://ed95ca3e1afe48daa84ee4fac0bae4e0@o1166653.ingest.sentry.io/6269399",
//   integrations: [
//     new BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(rutas),
//       tracingOrigins: ["localhost", "https://pos.paytiptap.com", /^\//],
//     }),
//   ],
//   tracesSampleRate: 1.0,
// });


Vue.filter('capitalizeAll', function (str) {
	var splitStr = str.toLowerCase().split(' ');
	for (var i = 0; i < splitStr.length; i++) {
		splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
	}
	return splitStr.join(' '); 
})

Vue.filter('capitalize', function (value) {
	if (!value) return '';
	value = value.toString();
	return value.charAt(0).toUpperCase() + value.slice(1);
})

if (process.env.NODE_ENV == 'production') {
	window.addEventListener('contextmenu', function (e) {
		e.preventDefault();
	}, false);
}

window.onkeydown = function (event) {
	if (event.keyCode == 9) {
		return false;
	}
}


new Vue({
	store,
	router: rutas,
  beforeCreate() {
    if(this.$store.state.auth.isLoggedIn()) {
      const token = localStorage.getItem('token');
      const finger = localStorage.getItem('finger');
      const account = localStorage.getItem('account');
      const profile = localStorage.getItem('profile');
      const institution = localStorage.getItem('institution');
      const products = localStorage.getItem('products');
      const id_sale_init = Object.prototype.hasOwnProperty.call(localStorage,'id_sale_init') ? localStorage.getItem('id_sale_init') : 0;
      const start_amount = localStorage.getItem('start_amount');
      const started = localStorage.getItem('started');
      const till_init_date = localStorage.getItem('till_init_date');
      this.$store.dispatch('preserveData', {token: token, finger: finger, products: products, account: account, profile: profile, institution: institution, id_sale_init: id_sale_init, start_amount: start_amount, started: started, till_init_date: till_init_date});
    }
  },
	render: h => h(App),
}).$mount('#app');
