import axios from '../axios';
import CONFIG from '../config/constants';
import { checkError } from '../helpers/checkAxiosError';

const timestamp = () => {
  const time = new Date();
  return time.getTime();
}

const actions = {
	start_amount({ commit }, amount) {
		commit('START_AMOUNT', amount);
	},
	getProducts({ commit }, idInstitution) {
		return new Promise((resolve, reject) => {
			return axios.get("/api/pos/products/active/" + idInstitution + "?t="+ timestamp())
				.then(response => {
					if (response.data.status) {
						commit('SET_PRODUCTS', response.data.data)
						return resolve(response.data)
					}
					else
						return reject(response.data.msg)
				})
				.catch(error => {
					reject(checkError(error));
				});
		})

	},
	getCategories({ commit }) {
		return new Promise((resolve, reject) => {
			return axios.get("/api/pos/categories"+ "?t="+ timestamp())
				.then(response => {
					if (response.data.status) {
						commit('SET_CATEGORIES', response.data.data.filter(c => c.status == 1))
					}
					else
						return reject(response.data)
				})
				.catch(error => {
					reject(checkError(error))
				});
		})

	},
	getIngredients({ commit }) {
		return new Promise((resolve, reject) => {
			return axios.get("/api/pos/ingredients"+ "?t="+ timestamp())
				.then(response => {
					if (response.data.status) {
						commit('SET_INGREDIENTS', response.data.data)
					} else
						return reject(response.data)
				})
				.catch(error => {
					reject(checkError(error))
				});
		})

	},
	getEntrystudent({ commit }, idStudent) {
		return new Promise((resolve, reject) => {
			return axios.get("/api/account/data/" + idStudent+ "?t="+ timestamp())
				.then(response => {
					if (response.data.status) {
						commit('SET_ENTRYSTUDENT', response.data.data)
						return resolve(response.data)
					}
					else
						return reject(response.data.msg)
				})
				.catch(error => {
					reject(checkError(error))
				});
		})
	},
	tillInit({ commit }, payload) {
		return new Promise((resolve, reject) => {
			return axios.post("/api/pos/till/init"+ "?t="+ timestamp(), { id_account: payload.id_account, id_institution: payload.id_institution, balance: payload.balance })
				.then(response => {
					if (response.data.status) {
						let data = response.data.data;
						commit('SET_SALE_INIT', data.id);
						commit('START_AMOUNT', data.balance);
						commit('SET_SALE_INIT_DATE', data.created_at.replace("T", " "));
						resolve(response.data);
					} else {
						reject(response.data);
					}
				})
				.catch(error => {
					reject(checkError(error))
				});
		})
	},
	setTillInit({ commit }, tillInit) {
		commit('SET_SALE_INIT', tillInit.id_sale_init);
		commit('START_AMOUNT', tillInit.balance);
		commit('SET_SALE_INIT_DATE', tillInit.tillinit_date.replace("T", " "));
	},
	getInit(context, id_sale_init) {
		return new Promise((resolve, reject) => {
			return axios.get("/api/pos/till/init/" + id_sale_init+ "?t="+ timestamp())
				.then(response => {
					if (response.status != 200)
						return reject(CONFIG.CONNECTION_ERROR);

					if (response.data.status) {
						return resolve(response.data);
					}
					else {
						reject(response.data.msg);
					}
				})
				.catch(error => {
					reject(checkError(error))
				});
		})
	},
	tillSale(context, payload) {
		return new Promise((resolve, reject) => {
			return axios.post("/api/pos/sales"+ "?t="+ timestamp(), {
				id_sale_init: payload.id_sale_init,
				id_institution: payload.id_institution,
				id_account_seller: payload.id_account_seller,
				id_account_buyer: payload.id_account_buyer,
				sale_type: payload.sale_type,
				sale_total: payload.sale_total,
				discount_value: payload.discount_value,
				discount_total: payload.discount_total,
				details: payload.details
			})
				.then(response => {
					if (response.status != 200)
						return reject(CONFIG.CONNECTION_ERROR);

					if (response.data.status) {
						return resolve(response.data);
					} else {
						reject(response.data.msg);
					}
				})
				.catch(error => {
					reject(checkError(error))
				});
		})

	},
	tillClose(context, payload) {
		return new Promise((resolve, reject) => {
			return axios.post("/api/pos/till/close"+ "?t="+ timestamp(),
				{
					id_sale_init: payload.id_sale_init,
					sale_te_amount: payload.sale_te_amount,
				})
				.then(response => {
					if (response.status != 200)
						return reject(response.data);

					if (response.data.status) {
						return resolve(response.data);
					} else {
						return reject(response.data);
					}
				})
				.catch(error => {
					reject(checkError(error))
				});
		})

	},
	setRecharge(context, payload) {
		return new Promise((resolve, reject) => {
			return axios.post("/api/pos/recharge"+ "?t="+ timestamp(),
				{
					id_sale_init: payload.id_sale_init,
					id_account_seller: payload.id_account_seller,
					id_account_buyer: payload.id_account_buyer,
					sale_total: payload.sale_total,
					id_institution: payload.id_institution
				})
				.then(response => {
					if (response.status == 404)
						return reject(CONFIG.CONNECTION_ERROR);

					if (response.data.status) {
						return resolve(response.data);
					} else {
						return reject(response.data.msg);
					}
				})
				.catch(error => {
					reject(checkError(error))
				});
		})

	},
	getSales(context, id_sale_init) {
		return new Promise((resolve, reject) => {
			return axios.get("/api/pos/sales/" + id_sale_init+ "?t="+ timestamp())
				.then(response => {
					if (response.status == 404)
						return reject(CONFIG.CONNECTION_ERROR);

					if (response.data.status) {
						return resolve(response.data);
					} else {
						return reject(response.data.msg);
					}
				})
				.catch(error => {
					reject(checkError(error))
				});
		})

	},

	setSaleType({ commit }, sale_type) {
		commit('SET_SALE_TYPE', sale_type);
	},

	getSaleDetails(context, id_sale) {
		return new Promise((resolve, reject) => {
			return axios.get("/api/pos/sales/detail/" + id_sale+ "?t="+ timestamp())
				.then(response => {
					if (response.status == 404)
						return reject(CONFIG.CONNECTION_ERROR);

					if (response.data.status) {
						return resolve(response.data);
					} else {
						return reject(response.data.msg);
					}
				})
				.catch(error => {
					reject(checkError(error))
				});
		})

	},
	getStudentDetails(context, id_student) {
		return new Promise((resolve, reject) => {
			return axios.get("/api/pos/sales/student/" + id_student + "?t="+ timestamp())
				.then(response => {
					if (response.status == 404)
						return reject(CONFIG.CONNECTION_ERROR);

					if (response.data.status) {
						return resolve(response.data);
					} else {
						return reject(response.data.msg);
					}
				})
				.catch(error => {
					reject(checkError(error))
				});
		})

	},

	getToClose(context, id_sale_init) {
		return new Promise((resolve, reject) => {
			return axios.get("/api/pos/till/close/total/" + id_sale_init + "?t="+ timestamp())
				.then(response => {
					if (response.status != 200)
						return reject(CONFIG.CONNECTION_ERROR);

					if (response.data.status) {
						return resolve(response.data);
					}
					else {
						reject(response.data.msg);
					}
				})
				.catch(error => {
					reject(checkError(error))
				});
		})
	},

	getManagers(context, idInstitution) {
		return new Promise((resolve, reject) => {
			return axios.get("/api/pos/managers/" + idInstitution + "?t="+ timestamp())
				.then(response => {
					if (response.status != 200)
						return reject(CONFIG.CONNECTION_ERROR);

					if (response.data.status) {
						return resolve(response.data);
					}
					else {
						reject(response.data.msg);
					}
				})
				.catch(error => {
					reject(checkError(error))
				});
		})
	},
	logout({ commit, getters }) {
		commit("RESET_STATE", getters.getDefaultState);
	},
	setReverse(context, payload) {
		return new Promise((resolve, reject) => {
			return axios.post("/api/pos/reverse" + "?t="+ timestamp(), payload)
				.then(response => {
					if (response.status == 404)
						return reject(CONFIG.CONNECTION_ERROR);

					if (response.data.status) {
						return resolve(response.data);
					} else {
						return reject(response.data.msg);
					}
				})
				.catch(error => {
					reject(checkError(error))
				});
		})
	},
	registerFingers(context, payload) {
		return new Promise((resolve, reject) => {
			return axios.post("/api/fingers/enrollment" + "?t="+ timestamp(),
				{
					fingers: payload.fingers,
					id_account: payload.id_account,
					hand: payload.hand,
					finger: payload.finger,
					account_type: payload.account_type
				})
				.then(response => {
					if (response.status == 404)
						return reject(CONFIG.CONNECTION_ERROR);

					if (response.data.status) {
						return resolve(response.data);
					} else {
						return reject(response.data.msg);
					}
				})
        .catch(error => {
          reject(checkError(error));
        });
		})
	},
	getFingerData({ commit }, payload) {
		return new Promise((resolve, reject) => {
			return axios.post("/api/fingers/verify" + "?t="+ timestamp(),
				{
					print: payload.print,
					id_institution: payload.id_institution,
					account_type: payload.account_type
				}
			)
				.then(response => {
					if (response.data.status) {
						commit('SET_ENTRYSTUDENT', response.data.data);
						return resolve(response.data);
					}
					else
						return reject(response.data);
				})
				.catch(error => {
					return reject(checkError(error))
				});
		});
	},
	getFingerManager({ commit }, payload) {
		return new Promise((resolve, reject) => {
			return axios.post("/api/fingers/verify" + "?t="+ timestamp(),
				{
					print: payload.print,
					account_type: payload.account_type,
					id_institution: payload.id_institution
				}
			)
				.then(response => {
					if (response.data.status) {
						commit('SET_ENTRYMANAGER', response.data.data);
						return resolve(response.data);
					}
					else
						return reject(response.data);
				})
				.catch(error => {
					reject(checkError(error))
				});
		});
	},
	getStudentByCardID(context, payload) {
		return new Promise((resolve, reject) => {
			return axios.get("/api/pos/student/card/" + payload.type + "/" + payload.number + "?t="+ timestamp())
				.then(response => {
					if (response.data.status) {
						return resolve(response.data);
					} else
						return reject(response.data);
				})
				.catch(error => {
					reject(checkError(error))
				});
		})
	},
	getRegisteredStudents(context, idInstitution) {
		return new Promise((resolve, reject) => {
			return axios.get("/api/pos/student/institution/" + idInstitution + "?t="+ timestamp())
				.then(response => {
					if (!response.data.status)
						reject(response.msg)

					resolve(response.data);
				})
				.catch(error => checkError(error))
		})
	},
	preserveData({commit}, data) {
		commit("SET_USER", {account: JSON.parse(data.account), profile: JSON.parse(data.profile), institutions: [JSON.parse(data.institution)]});
		commit("START_AMOUNT", JSON.parse(data.start_amount));
		commit("SET_PRODUCTS", JSON.parse(data.products));
		commit("SET_SALE_INIT", data.id_sale_init);
		commit("SET_SALE_INIT_DATE", data.till_init_date);
		commit("SET_BEARER", data.token);
	},
}

export default actions