const getDefaultState = () => {
	return {
		totales: 0,
		started: false,
		start_amount: 0,
		isLogued: false,
		account: {},
		profile: {},
		seller: {},
		products: [],
		categories: [],
		student: {},
		id_sale_init: 0,
		sale_type: 0,
		till_init_date: 0,
	}
};

export default getDefaultState;