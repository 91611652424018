export default {
    isLoggedIn: () => {
        let isAuthenticated = false

        let exp = localStorage.getItem('expire');

        if (new Date(Date.now()) < new Date(exp * 1000)) isAuthenticated = true;

        return isAuthenticated;
    },
    isAutenticated: false
}