export default {
    SALE_TYPE: {
        TIPTAP:1,
        CARD:2,
        CASH:3,
        COURTESY:4
    },
    MONEY: "$",
    CONNECTION_ERROR: "Error de conexión",
    FINGERPRINT_TEST: "AOg2Acgp43NcwEE381mKa55cZ2bEPJkLU-EE27fB20J61sd-2JD_mQs4my-X4uj6lDTV0ZXJaGsw2iSS1wqyGk4UFPKL5DoDguMiCcwrBPfpA_JsWyHmOt21kauhekD-adaVRCnPEQ4z0LnswoG5vmh0SzpxoIGRa8qanXFLYb0XhLwO6QOg_s0P-_6ip5yXDZrA7F4Za8XO8wnn2PnBzUTRiC0ViOEA3mPdiXW4CSlv8zGPEr2pPoK32psK3V6nxqXDFRYbWjLvc7GjLJjAQufyfD2kx2ZTqWqsrw93xiA06XboyfH1hLUJBDcXFf64umULd-06ojRCpXpEmuNHbU5jhOfGnFuuAy_vhnm0BMczD8MNyrh7wvEcQIs09vWdcgr8idbq6DsUJyhCls8t4p4ms2uaQmkF3v1vAAAA"
}