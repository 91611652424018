import axios from "../axios";

const mutations = {
    START_AMOUNT(state, amount) {
        localStorage.setItem("started", true);
        localStorage.setItem("start_amount", amount);
        state.started = true;
        state.start_amount = amount;
    },
    SET_PRODUCTS(state, products) {
        localStorage.setItem("products", JSON.stringify(products));
        state.products = products;
    },
    SET_CATEGORIES(state, categories) {
        localStorage.setItem("categories", JSON.stringify(categories));
        state.categories = categories;
    },
    SET_INGREDIENTS(state, ingredients) {
        localStorage.setItem("ingredients", JSON.stringify(ingredients));
        state.Ingredients = ingredients;
    },
    SET_ENTRYSTUDENT(state, student) {
        localStorage.setItem("student", JSON.stringify(student))
        state.student = student;
    },
    SET_ENTRYMANAGER(state,manager){
        localStorage.setItem("manager", JSON.stringify(manager))
        state.manager = manager;
    },
    SET_SALE_INIT(state, id_sale_init) {
        localStorage.setItem("id_sale_init",id_sale_init)
        state.id_sale_init = id_sale_init;
    },
    SET_SALE_INIT_DATE(state, tillInit_date) {
        localStorage.setItem("till_init_date", tillInit_date);
        state.till_init_date = tillInit_date;
    },
    SET_SALE_TYPE(state, sale_type) {
        state.sale_type = sale_type;
    },
    RESET_STATE(state, def) {
        Object.assign(state, def)
    },
    SET_USER(state, user) {
      state.isAutenticated = true;
      localStorage.setItem('account', JSON.stringify(user.account));
      localStorage.setItem('profile', JSON.stringify(user.profile));
      localStorage.setItem('institution', JSON.stringify(user.institutions[0]));
    }, 
    SET_BEARER(state, accessToken) {
        localStorage.setItem('token', accessToken);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
    },
};

export default mutations;

