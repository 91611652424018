const getters = {
    totales: state => state.totales,
    isLogued: state => state.isLogued,
    getInstitution: () => { return async () => localStorage.getItem('institution') },
    getProfile: () => { return async () => localStorage.getItem('profile') },
    getProducts: state => { return async () => state.products },
    getCategories: state => { return async () => state.categories },
    getEntrystudent: state => { return async () => state.student },
    getAccount: () => { return async () => localStorage.getItem("account") },
    getIdTillInit: state => { return async () => state.id_sale_init },
    getStartAmount: state => { return async () => state.start_amount },
    getSaleType: state => { return async () => state.sale_type },
    getStarted: state => { return async () => state.started },
    getIngredients: () => { return async () => localStorage.getItem('ingredients') },
    getTillInitDate: state => state.till_init_date,
    getDefaultState: () => {
        return {
            totales: 0,
            started: false,
            start_amount: 0,
            isLogued: false,
            account: {},
            profile: {},
            seller: {},
            products: [],
            categories: [],
            student: {},
            id_sale_init: 0,
            sale_type: 0,
            till_init_date: 0,
        }
    }
};
export default getters;