// axios
import axios from 'axios'

const domain = process.env.VUE_APP_DOMAIN || "algo";

axios.create({
  baseURL: domain,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    "access-control-allow-origin": "*",
    "access-control-allow-methods": "GET,POST,OPTIONS,PUT,DELETE,PATCH",
    "Access-Control-Allow-Headers": "Content-Type, Accept, Access-Control-Allow-Origin, access-control-allow-headers",
    "Cache-Control": "no-cache",
    "Pragma": "no-cache"
  },
})

axios.interceptors.request.use(
  (config) => {
    
    const token = localStorage.getItem('token');
    const finger = localStorage.getItem('finger');
    config.baseURL = domain;
    config.timeout = process.env.VUE_APP_API_TIMEOUT || 60000

    console.log('timeout', config.timeout)

    if (token) config.headers['Authorization'] = `Bearer ${ token }`;
    if (finger) config.headers['x-secure-identity'] = finger;
    
    return config;
  },
);

export default axios;