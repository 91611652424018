import Vue from 'vue';
import Vuex from 'vuex';
import store from './store';
import mutations from './mutations';
import getters from './getters';
import actions from './actions';
import moduleAuth from './auth';

const state = store;

Vue.use(Vuex);

export default new Vuex.Store({
	actions,
	mutations,
	state,
	getters,
	modules: {
		auth: moduleAuth
	},
	strict: process.env.NODE_ENV !== 'production'
});