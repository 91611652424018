<template>
  <div id="app" :key="appkey" :data-key="appkey">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  data () {
    return {
      appkey: require('../package.json').version
    }
  }
}; 
</script>
<style scoped>
#app {
  height: 100%;
}
</style>
